.Kuur7G_btn {
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 166px;
  height: 46px;
  font-size: 16px;
  transition: all .15s linear;
  display: flex;
  position: relative;
}

.Kuur7G_btn:disabled {
  color: #282828;
  cursor: not-allowed;
  background-color: #e9e9e9;
  border: none;
}

.Kuur7G_btn:disabled:hover {
  color: #282828;
  background-color: #e9e9e9;
}

.Kuur7G_btn:active, .Kuur7G_btn:focus {
  outline: 1px solid #000;
}

.Kuur7G_lightBlue {
  color: #fff;
  background-color: #00a3ff;
}

.Kuur7G_lightBlue i {
  border-style: solid;
  border-color: #666;
}

.Kuur7G_white {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.Kuur7G_white i {
  border-style: solid;
  border-color: #666;
}

.Kuur7G_gray {
  color: #dedede;
  background-color: #666;
  border: none;
}

.Kuur7G_gray i {
  border-style: solid;
  border-color: #dedede;
}

.Kuur7G_lightGray {
  color: #818183;
  background-color: #f4f7fa;
  border: none;
}

.Kuur7G_lightGray i {
  border-style: 2px solid #818183;
}

.Kuur7G_black {
  color: #f8f8f8;
  background-color: #010101;
  border: none;
}

.Kuur7G_black i {
  border-style: solid;
  border-color: #f8f8f8;
}

.Kuur7G_blue {
  color: #fff;
  background-color: #1b6ae0;
  border: none;
}

.Kuur7G_blue i {
  border-style: solid;
  border-color: #f8f8f8;
}

.Kuur7G_blue:hover {
  background-color: #1b5de0;
}

.Kuur7G_blue:active, .Kuur7G_blue:focus {
  outline: 1px solid #1b6ae0;
}

.Kuur7G_blue2 {
  color: #fff;
  background-color: #3686ff;
  border: none;
}

.Kuur7G_blue2 i {
  border-style: solid;
  border-color: #f8f8f8;
}

.Kuur7G_blue2:hover {
  background-color: #1b5de0;
}

.Kuur7G_blue2:active, .Kuur7G_blue2:focus {
  outline: 1px solid #3686ff;
}

.Kuur7G_blueText {
  color: #1b6ae0;
  background-color: #0000;
  border: 1px solid #1b6ae0;
}

.Kuur7G_blueText i {
  border-style: solid;
  border-color: #f8f8f8;
}

.Kuur7G_blueText:hover {
  color: #e9e9e9;
  background-color: #1b6ae0;
}

.Kuur7G_blueText:disabled {
  color: #282828;
  background-color: #e9e9e9;
  border: none;
}

.Kuur7G_lightBlueText {
  color: #1f95f2;
  background-color: #fff;
  border: 1px solid #1f95f2;
}

.Kuur7G_lightBlueText i {
  border-style: solid;
  border-color: #666;
}

.Kuur7G_lightBlueText:hover {
  color: #fff;
  background-color: #0086ff;
}

.Kuur7G_blue2Text {
  color: #3686ff;
  background-color: #fff;
  border: 1px solid #3686ff;
}

.Kuur7G_blue2Text i {
  border-style: solid;
  border-color: #666;
}

.Kuur7G_blue2Text:hover {
  color: #fff;
  background-color: #3686ff;
}

.Kuur7G_redText:disabled {
  color: #282828;
  background-color: #e9e9e9;
  border: none;
}

.Kuur7G_redText {
  color: #de350b;
  background-color: #0000;
  border: 1px solid #de350b;
}

.Kuur7G_redText i {
  border-style: solid;
  border-color: #f8f8f8;
}

.Kuur7G_redText:hover {
  color: #e9e9e9;
  background-color: #de350b;
}

.Kuur7G_lightGray {
  color: #282828;
  border: none;
}

.Kuur7G_lightGray i {
  border-style: solid;
  border-color: #dedede;
}

.Kuur7G_lightGray:hover {
  background-color: #e9e9e9;
}

.Kuur7G_lightGray1 {
  color: #282828;
  background-color: #f9f9f9;
  border: 1px solid #e9e9e9;
}

.Kuur7G_lightGray1 i {
  border-style: solid;
  border-color: #dedede;
}

.Kuur7G_lightGray1:hover {
  background-color: #e9e9e9;
}

.Kuur7G_green {
  color: #fff;
  background-color: #00ae00;
  border: none;
}

.Kuur7G_green i {
  border-style: solid;
  border-color: #dedede;
}

.Kuur7G_green:hover {
  background-color: #00ae00;
}

.Kuur7G_greenPrimary {
  color: #fff;
  background-color: #0a9484;
  border: none;
}

.Kuur7G_greenPrimary i {
  border-style: solid;
  border-color: #dedede;
}

.Kuur7G_greenPrimary:hover {
  background-color: #0a9484;
}

.Kuur7G_red {
  color: #fff;
  background-color: #de350b;
  border: none;
}

.Kuur7G_red i {
  border-style: solid;
  border-color: #f8f8f8;
}

.Kuur7G_red:hover {
  background-color: #de350b;
}

.Kuur7G_darkGray {
  color: #f8f8f8;
  background-color: #282828;
  border: none;
}

.Kuur7G_darkGray i {
  border-style: solid;
  border-color: #dedede;
}

.Kuur7G_darkGray:hover {
  background-color: #282828;
}

.Kuur7G_darkGray1 {
  color: #f8f8f8;
  background-color: #808f99;
  border: 1px solid #fff;
}

.Kuur7G_darkGray1 i {
  border-style: solid;
  border-color: #dedede;
}

.Kuur7G_darkGray2 {
  color: #f8f8f8;
  background-color: #151515;
  border: 1px solid #fff;
}

.Kuur7G_darkGray2 i {
  border-style: solid;
  border-color: #dedede;
}

.Kuur7G_darkGray2:hover {
  background-color: #151515;
}

.Kuur7G_gold {
  color: #fff;
  background-color: #db8c00;
  border: 2px solid #ffc152;
}

.Kuur7G_gold i {
  border-style: solid;
  border-color: #dedede;
}

.Kuur7G_gold:hover {
  background: linear-gradient(#996229, #996229) padding-box padding-box, linear-gradient(270deg, #ffc152 0%, #996229 100%) border-box;
  border: 2px solid #0000;
}

.Kuur7G_yellow {
  color: #f8f8f8;
  background: #f2b61b;
}

.Kuur7G_yellow:hover {
  background-color: #f2b61b;
}

.Kuur7G_transparent {
  background-color: #fff;
  border: none;
}

.Kuur7G_rounded {
  border-radius: 22.5px;
}

.Kuur7G_semiRounded {
  border-radius: 13px;
}

.Kuur7G_arrowBtn {
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 100%;
  margin-left: 20px;
  display: flex;
  position: absolute;
  right: 10px;
}

.Kuur7G_arrow {
  border-width: 0 1px 1px 0;
  padding: 3px;
}

.Kuur7G_down {
  margin-bottom: 4px;
  transform: rotate(45deg);
}

.Kuur7G_lightBlueShadow {
  box-shadow: 0 4px 23px 16px #0086ff47;
}

.Kuur7G_right {
  margin-left: auto;
}

.Kuur7G_left {
  margin-right: auto;
}

@media only screen and (width <= 900px) {
  .Kuur7G_btnFullSizeResponsive {
    width: 100%;
  }
}

@media only screen and (width <= 600px) {
  .Kuur7G_btn {
    font-size: 14px;
  }
}

._91OMPq_loader {
  aspect-ratio: 1;
  border-radius: 50%;
  margin: auto;
  animation: 1s linear infinite _91OMPq_spin;
  display: flex;
}

._91OMPq_loader:before {
  content: "";
  aspect-ratio: 1;
  border-radius: 50%;
  margin: auto;
}

._91OMPq_loader._91OMPq_bgColor-white:before {
  background-color: #fff;
}

._91OMPq_loader._91OMPq_bgColor-lightBlue:before {
  background-color: #f8fbfe;
}

._91OMPq_loader._91OMPq_bgColor-gray:before {
  background-color: #e9e9e9;
}

._91OMPq_loader._91OMPq_bgColor-lightGray:before {
  background-color: #f9f9f9;
}

._91OMPq_loader._91OMPq_scale-1 {
  width: 20px;
}

._91OMPq_loader._91OMPq_scale-2 {
  width: 30px;
}

._91OMPq_loader._91OMPq_scale-3 {
  width: 40px;
}

._91OMPq_loader._91OMPq_scale-4 {
  width: 50px;
}

._91OMPq_loader._91OMPq_scale-5 {
  width: 60px;
}

._91OMPq_loader._91OMPq_scale-6 {
  width: 70px;
}

._91OMPq_loader._91OMPq_scale-7 {
  width: 80px;
}

._91OMPq_loader._91OMPq_scale-8 {
  width: 90px;
}

._91OMPq_loader._91OMPq_scale-9 {
  width: 100px;
}

._91OMPq_loader._91OMPq_scale-10 {
  width: 120px;
}

._91OMPq_loader._91OMPq_borderScale-1:before {
  width: calc(100% - 1px);
}

._91OMPq_loader._91OMPq_borderScale-2:before {
  width: calc(100% - 2px);
}

._91OMPq_loader._91OMPq_borderScale-3:before {
  width: calc(100% - 3px);
}

._91OMPq_loader._91OMPq_borderScale-4:before {
  width: calc(100% - 4px);
}

._91OMPq_loader._91OMPq_borderScale-5:before {
  width: calc(100% - 5px);
}

._91OMPq_loader._91OMPq_black {
  background: conic-gradient(#2828280a, #2828280d, #000);
}

._91OMPq_loader._91OMPq_blue {
  background: conic-gradient(#dad6f90a, #dad6f90d, #1b6ae0);
}

._91OMPq_loader._91OMPq_pauseAnimation {
  animation-play-state: paused;
}

._91OMPq_spinner {
  border: 3px solid #e0edff4a;
  border-top-color: #fff;
}

@keyframes _91OMPq_spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-Regular.845eaf67.otf");
}

._6hBIKq_heading {
  width: fit-content;
}

._6hBIKq_h2 {
  font-size: 24px;
}

._6hBIKq_h3 {
  font-size: 20px;
}

._6hBIKq_h4 {
  font-size: 18px;
}

._6hBIKq_bold {
  font-weight: bold;
}

._6hBIKq_medium {
  font-weight: 600;
}

._6hBIKq_light-medium {
  font-weight: 500;
}

._6hBIKq_normal {
  font-weight: normal;
}

._6hBIKq_gray {
  color: #666;
}

._6hBIKq_darkGray {
  color: #282828;
}

._6hBIKq_lightGray {
  color: #d6d6d6;
}

._6hBIKq_lightGray1 {
  color: #e6e6e6;
}

._6hBIKq_black {
  color: #000;
}

._6hBIKq_black2 {
  color: #072438;
}

._6hBIKq_white {
  color: #fff;
}

._6hBIKq_blue {
  color: #1f95f2;
}

._6hBIKq_blue2 {
  color: #3686ff;
}

._6hBIKq_darkBlue {
  color: #1b6ae0;
}

._6hBIKq_green {
  color: #02ac19;
}

._6hBIKq_teal {
  color: #0a9483;
}

._6hBIKq_red {
  color: #de350b;
}

@media only screen and (width <= 600px) {
  ._6hBIKq_h2, ._6hBIKq_h3 {
    font-size: 18px;
  }
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-Regular.845eaf67.otf");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

._34Mbfa_text {
  width: fit-content;
}

._34Mbfa_bold {
  font-weight: bold;
}

._34Mbfa_medium {
  font-weight: 600;
}

._34Mbfa_light-medium {
  font-weight: 500;
}

._34Mbfa_normal {
  font-weight: normal;
}

._34Mbfa_gray {
  color: #666;
}

._34Mbfa_gray2 {
  color: #a3a3a3;
}

._34Mbfa_gray3 {
  color: #818183;
}

._34Mbfa_gray4 {
  color: #557098;
}

._34Mbfa_gray5 {
  color: #999;
}

._34Mbfa_gray6 {
  color: #383a3b;
}

._34Mbfa_gray7 {
  color: #9c9c9c;
}

._34Mbfa_gray8 {
  color: #919193;
}

._34Mbfa_gray9 {
  color: #4b4747;
}

._34Mbfa_gray10 {
  color: #cecece;
}

._34Mbfa_gray11 {
  color: #9494a0;
}

._34Mbfa_darkGray {
  color: #282828;
}

._34Mbfa_lightGray {
  color: #e9e9e9;
}

._34Mbfa_lightGray2 {
  color: #9c9c9c;
}

._34Mbfa_lightGray3 {
  color: #818183;
}

._34Mbfa_lightGray4 {
  color: #e6e6e6;
}

._34Mbfa_normalGray {
  color: #acacac;
}

._34Mbfa_normalGray2 {
  color: #cecece;
}

._34Mbfa_black {
  color: #000;
}

._34Mbfa_black2 {
  color: #072438;
}

._34Mbfa_black3 {
  color: #383a3b;
}

._34Mbfa_white {
  color: #fff;
}

._34Mbfa_blue {
  color: #1f95f2;
}

._34Mbfa_blue2 {
  color: #3686ff;
}

._34Mbfa_darkBlue {
  color: #1b6ae0;
}

._34Mbfa_orange {
  color: #f0ad4e;
}

._34Mbfa_gold {
  color: #db8c00;
}

._34Mbfa_red {
  color: #f70000;
}

._34Mbfa_green {
  color: #00ae00;
}

._34Mbfa_lightGreen {
  color: #2dc128;
}

@media only screen and (width <= 600px) {
  ._34Mbfa_text {
    font-size: 14px;
  }
}

.i1AMOq_bead {
  border-radius: 100%;
  width: 11px;
  height: 11px;
}

.i1AMOq_gray {
  background-color: #666;
}

.i1AMOq_lightgray {
  background-color: #d4d4d4;
}

.i1AMOq_darkGray {
  background-color: #282828;
}

.i1AMOq_black {
  background-color: #000;
}

.i1AMOq_white {
  background-color: #fff;
}

.i1AMOq_green {
  background-color: #00ae00;
}

.i1AMOq_red {
  background-color: #de350b;
}

.i1AMOq_lightOrange {
  background-color: #ffa700;
}

.i1AMOq_lightBlue {
  background-color: #40addc;
}

.ZMMRoa_pageWrapper {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.ZMMRoa_errorBoundaryImg {
  border: 10px solid #282828;
  border-radius: 30%;
  width: 120px;
  height: 120px;
  margin: auto;
  transform: rotate(45deg);
}

.ZMMRoa_errorExclamation {
  width: 10px;
  height: 73px;
  margin: 14px auto;
  transform: rotate(-45deg);
}

.ZMMRoa_errorLine {
  background-color: #282828;
  border-radius: 20px;
  width: 10px;
  height: 53px;
}

.nncGha_loaderWrapper {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.nncGha_loaderWrapper.nncGha_fillScreen {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
}

.nncGha_loaderWrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/*# sourceMappingURL=index.dc0f8c3e.css.map */
